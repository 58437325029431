@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';

.login-parent-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: size(xs);
  padding-inline: size(xs);
  background-color: #F5F6FF;

  .version-label {
    position: fixed;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
  }
  .body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 10;
    background-color: #F5F6FF;
  }

  .app-logo {
    width: 40%;
    flex: 0.5;
    align-self: center;
    filter: drop-shadow(4px 6px 3px rgba(0, 0, 0, 0.05));

  }

  .card {
    background: color($c-cardBg);
    border-radius: size(xs);
    padding-inline: size(xl);
    padding-block: size(s);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0;
    flex-direction: row;
    z-index: 1;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
    //min-width: 55%;
    //min-height: 60%;

    .contact-information {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;

      .image-container {
        flex: 1;
        border-radius: size(xxs);
        align-self: center;
        padding-top: size(xxs);
      }
    }

    .login {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: size(m);
    }

  }
}


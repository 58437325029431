

// For default size variant
$default_screen_width: 1512px;
$default_screen_height: 982px;
$large_card_width: 953px;
$large_card_height: 599px;
$small_card_width: 464px;
$small_card_height: 230px;
$size_button_height: 44px;
$size_px_button_height: 44px;
$size_px_text_field_height: 44px;
$logo_toolbar_width: 85.55px;
$logo_toolbar_height: 32;
$size_border_width: 2px;
$size_label_error_height: 16px;
$size_thin_border_width: 1px;
$checkbox_border_width: 3px;
$xxs_spacing: 4px;
$xs_spacing: 8px;
$s_spacing: 12px;
$m_spacing: 16px;
$l_spacing: 20px;
$xl_spacing: 24px;
$xxl_spacing: 32px;
$xxxl_spacing: 48px;
$xxxxl_spacing: 64px;

// For phone size variant
$screen_width: 600px;

$size-base: 20;
$size-variants: (
        xxxs: 0.16666667,
        xxs: 0.33333333,
        xs: 0.5,
        s: 0.66666667,
        m: 1.33333333,
        l: 2,
        xl: 2.66666667,
        xxl: 5.33333333,
        xxxl: 10.66666667,
);

@function _size($factor, $multiplier) {
  @return $factor * $multiplier
}

@function size($name) {
  @return _size(1, map-get($size-variants, $name)) + rem;
}

@function size-px($name) {
  @return _size($size-base, map-get($size-variants, $name)) + px;
}

$size-button-height: calc(#{size(m)} + #{size(xs)});
$size-text-field-height: 44px;
$size-px-button-height: calc(#{size-px(m)} + #{size-px(xs)});
$size-px-text-field-height: size-px(l);
$size-border-width: 2px;
$size-label-error-height: size(m);
$size-thin-border-width: 1px;
$checkbox-border-width: 3px;


.download-icon {
  width: 40px;
  height: auto;
  cursor: pointer;
}

.table-cell-select {
  //background-color: maroon;
  display: flex;
  padding-inline: size(xxl);
  align-items: center;
  justify-content: flex-end;
}

@import '../../../foundation/dls/theme/generated.colors';
@import '../../../foundation/dls/sizes/generated.sizes';

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].loading-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.loading-overlay {
  background: rgba(255, 255, 255, 0.75);
}

[data-popup='tooltip'].loading-overlay {
  background: transparent;
}


.blocking-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.blocking-loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blocking-loading-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].blocking-loading-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.blocking-loading-overlay {
  background: rgba(245, 246, 255, 1);
}

[data-popup='tooltip'].blocking-loading-overlay {
  background: transparent;
}

@import '../../../foundation/dls/theme/generated.colors';
@import '../../../foundation/dls/sizes/generated.sizes';
@import '../../../foundation/dls/sizes/generated.fonts';


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
}


.mandatory-field {
  color: color($c-primary);
  font-size: 20px;
  font-weight: bold;
}

.text-field-input-label {
 color: color($c-text);
  font-size: 14px;
  font-weight: bold;
}

.text-field-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  flex: 1 0;


 .text-field-new-disable {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   gap: 13px;
   height: $size-text-field-height;
   width: 100%;
   background: color($c-neutral);
   border-radius: 15px;
   padding: 0 size(xxs) 0 size(xs);
   transition: border 0.25s linear;

   .text-field-input {
     width: 100%;
     height: 100%;
     font-size: 20px;
     background-color: transparent;
     border: none;
     outline: none !important;
     box-shadow: none !important;
   }

   .text-field-input::placeholder {
     color: color($c-greyDivider)
   }

   .text-field-input:focus-within {
     border: none;
     box-shadow: none !important;
     outline: none !important;
   }
 }

  .text-field-new {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    height: 30px;
    //width: 100%;
    flex: 1 1 auto;
    background: color($c-white);
    border: $size-border-width solid white;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));

    border-radius: size(xs);
    padding: size(s);
    transition: border 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 16px;
      background-color: transparent;
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }

    .text-field-input::placeholder {
      color: color($c-greyDivider)
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-new-table {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 0.5px solid color($c-primary);
    border-radius: 8.5px;
    padding: 0 size(s) 0 size(xxxs);
    transition: all 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 13px;
      background-color: transparent;
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }

    .text-field-input::placeholder {
      color: color($c-greyDivider)
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-grey-new {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    height: $size-text-field-height;
    width: 100%;
    background: color($c-neutral);
    border: $size-border-width solid color($c-neutral);
    border-radius: 15px;
    padding: 0 size(xxs) 0 size(xs);
    transition: border 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: transparent;
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }

    .text-field-input::placeholder {
      color: color($c-greyDivider)
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-borderless-new {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    height: 39px;
    width: 100%;
    background: color($c-white);
    border: $size-border-width solid color($c-white);
    border-radius: 15px;
    padding: 0 size(xxs) 0 size(xs);
    transition: border 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: transparent;
      border: none;
      outline: none !important;
      box-shadow: none !important;
    }

    .text-field-input::placeholder {
      color: color($c-greyDivider)
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-without-label-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 13px;
    height: 32px;
    width: 100%;
    background: color($c-white);
    border: 1px solid color($c-black);
    border-radius: 6px;
    padding: 4px 12px 4px 12px;
    transition: border 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: transparent;
      border: none;
      text-align: center;
      outline: none !important;
      box-shadow: none !important;
      input:-webkit-autofill {
        background-color: transparent !important;
      }
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-without-label-input-disable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 13px;
    height: 32px;
    width: 100%;
    background: color($c-greyDivider);
    border-radius: 6px;
    padding: 4px 12px 4px 12px;
    transition: border 0.25s linear;

    .text-field-input {
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: transparent !important;
      border: none;
      text-align: center;
      outline: none !important;
      box-shadow: none !important;

      input:-webkit-autofill {
        background-color: transparent !important;
      }
    }

    .text-field-input input {
      background-color: transparent !important;
    }

    .text-field-input-disable {
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: color($c-greyDivider);
      border: none;
      text-align: center;
      outline: none !important;
      box-shadow: none !important;
    }

    .text-field-input:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-new-multiline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 13px;
    height: 55px;
    width: 100%;
    background: color($c-white);
    border: $size-border-width solid color($c-greyDivider);
    border-radius: 10px;
    padding: 0 size(xxs) 0 size(xs);
    transition: border 0.25s linear;

    .text-field-input-multiline {
      width: 100%;
      height: 100%;
      font-size: 20px;
      word-break: break-word;
      resize: none;
      background-color: transparent;
      border: none;
      outline: none !important;
      box-shadow: none !important;

    }

    .text-field-input-multiline::placeholder {
      color: color($c-greyDivider)
    }

    .text-field-input-multiline:focus-within {
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .text-field-new-withPrefix-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0px;
    height: $size-text-field-height;
    border: $size-border-width solid color($c-greyDivider);
    border-radius: 15px;
    width: 100%;
    transition: border 0.25s linear;
    background: color($c-white);


    .text-field-new-withPrefix-left-area {
      display: flex;
      justify-content: center;
      background: color($c-cardBg);
      border-right: $size-border-width solid color($c-greyDivider);
      height: 100%;
      border-radius: 15px 0px 0px 15px;
      width: 69px;
      align-items: center;
      padding: 0 size(xxs) 0 size(xs);
    }

    .text-field-new-withPrefix-right-area {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
      padding: 0 size(xxs) 0 size(xxs);

      .text-field-input-withPrefix {
        width: 100%;
        height: 100%;
        font-size: 20px;
        background-color: transparent;
        border: none;
        outline: none !important;
        box-shadow: none !important;

      }

      .text-field-input-withPrefix::placeholder {
        color: color($c-greyDivider)
      }

      .text-field-input-withPrefix:focus-within {
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  .text-field-new-withPrefixIcon-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0px;
    height: $size-text-field-height;
    border: $size-thin-border-width solid color($c-neutral);
    border-radius: 15px;
    width: 100%;
    transition: border 0.25s linear;

    .text-field-new-withPrefixIcon-left-area {
      display: flex;
      justify-content: center;
      border-right: $size-thin-border-width solid color($c-neutral);
      height: 100%;
      border-radius: 15px 0px 0px 15px;
      width: 50px;
      align-items: center;
      padding: 0 size(xs) 0 size(xs);

      .prefix-icon {
        width: 19px;
        height: auto;
      }
    }

    .text-field-new-withPrefixIcon-right-area {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
      padding: 0 size(xxxs) 0 size(xxxs);

      .text-field-input-withPrefixIcon {
        width: 100%;
        height: 100%;
        font-size: 20px;
        background-color: transparent;
        border: none;
        outline: none !important;
        box-shadow: none !important;
        color: color($c-darkGrey);
        font-weight: 350;
      }

      .text-field-input-withPrefixIcon::placeholder {
        color: color($c-greyDivider)
      }

      .text-field-input-withPrefixIcon:focus-within {
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }

  .text-field-new-withPrefix-container:focus-within {
    border: $size-border-width solid color($c-primary);
  }

  .text-field-new-withPrefixIcon-container:focus-within {
    border: $size-thin-border-width solid color($c-primary);
  }

  .text-field-new-multiline:focus-within {
    border: $size-border-width solid color($c-primary);
  }

  .text-field-new:focus-within {
    border: $size-border-width solid color($c-primary);
  }

  .outer-label-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    width: 100%;

    .description-label {
      margin-left: 9px;
    }
  }

  .inner-label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0px;
    column-gap: 4px;
    justify-content: flex-start;
    width: 100%;
    padding-left: size(xxs);
  }
}

@import '../../../foundation/dls/sizes/generated.fonts';
@import '../../../foundation/dls/sizes/generated.sizes';
@import '../../../foundation/dls/theme/generated.colors';

button {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding-left: $xl_spacing;
  padding-right: $xl_spacing;
  padding-block: 1rem;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));

  .button-text-bold {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    font-size: font-size($f-subtitle);
    font-weight: bold;
    flex: 1;
  }

  .button-text-regular {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: font-size($f-subtitle);
    font-weight: normal;
  }

  .button-text-small {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: font-size($f-small);
    font-weight: 700;
  }
}

button:focus {
  box-shadow: none;
}

button.primary {
  background: color($c-primary);
  color: color($c-white);
  transition: background 0.5s linear;
}

button.primaryLarge {
  background: color($c-primary);
  color: color($c-white);
  transition: background 0.5s linear;
  padding: 15px 24px;
}

button.slimPrimary {
  background: color($c-primary);
  color: color($c-white);
  transition: all 0.25s ease;
  padding-left: size(s);
  padding-right: size(s);
  padding-block: 0.19rem;
}

button.miniPrimary {
  background: color($c-primary);
  color: color($c-white);
  transition: all 0.25s ease;
  padding-left: size(xs);
  padding-right: size(xs);
  padding-block: 3px;
}

button.slimTransparent {
  background: transparent;
  color: color($c-primary);
  transition: all 0.25s ease;
  padding-left: size(xxs);
  padding-right: size(xxs);
  padding-block: 0.19rem;
}

button.slimTransparentBlack {
  background: transparent;
  color: color($c-black);
  transition: all 0.25s ease;
  padding-left: size(xxs);
  padding-right: size(xxs);
  padding-block: 0.19rem;
}

button.grow {
  background: color($c-primary);
  color: color($c-white);
  transition: background 0.5s linear;
  flex: 1;
  padding-block: size(xs);
}

button.fill {
  background: color($c-primary);
  color: color($c-white);
  transition: background 0.5s linear;
  flex: 0;
  align-self: stretch;
  padding-block: size(xs);
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

button.secondary {
  background: color($c-neutral);
  color: color($c-text);
}

button.textField {
  border-radius: 0px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  height: 42.5px;
  background: color($c-primary);
  color: color($c-white);
  width: 20px;
  padding-left: $s_spacing;
  padding-right: $s_spacing;
  flex: 1;
}

button.transparent {
  background: transparent;
  color: color($c-primary);
}
button.redBackground {
  background: color($c-danger);
  color: color($c-white);
  transition: background 0.5s linear;
}

button.transparentWithBlackLabel {
  background: transparent;
  color: color($c-text);
}

button.transparentWithRedLabel {
  background: transparent;
  color: color($c-danger);
}

button.PagerNumberButton {
  background: transparent;
  display: flex;
  color: color($c-text);
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 2px;
  padding-block: 0;
  height: 24px;
  min-width: 24px;
  outline: none;
  padding-bottom: 23px;
  border: 1px solid transparent;;
}

button.PagerNumberButtonFocus {
  background: transparent;
  color: color($c-primary);
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 2px;
  padding-block: 0;
  height: 24px;
  min-width: 24px;
  padding-bottom: 23px;
  border: 1px solid color($c-primary);;
}

button:disabled,
button[disabled]{
  background: color($c-disabled);
  color: color($c-textContrast);
  border: none;
  cursor: not-allowed;
  transition: background 0.5s linear;
}


button.icon-button{
  background: color($c-white);
  border: solid $size-border-width color($c-primary);
  border-radius: size(xxs);
  padding-block: size(xxxs);
  color: color($c-primary);
  display: flex;
  flex:0;
  align-items: center;
  justify-content: center;
  .button-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    font-size: font-size($f-subtitle);
    font-weight: bold;
  }
}

.icon {
  width: 20px;
}

.icon-container {
  display: flex;
  flex:0;
  align-items: center;
  justify-content: center;
}

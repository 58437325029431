@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';


.home-parent-container {
  width: 100%;
  height: 100%;
  flex: 0;
  display: flex;
  flex-direction: column;

  .home-body {
    background-color: #F5F6FF;
    flex: 10 10 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 1;
    padding: size(m);
    padding-inline: size(l);

    .box-container {
      flex: 0 0 auto;
      justify-content: stretch;
      align-items: flex-start;
      display: flex;
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));

      .placeholder-box {
        display: flex;
        flex: 1 2;
        padding-inline: size(l);
        padding-block: size(l);
        border-radius: size(xs);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .box {
        display: flex;
        flex: 1 2;
        background-color: color($c-cardBg);
        padding-inline: size(l);
        padding-block: size(l);
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        border-radius: size(xs);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .icon-holder {
          background-color: color($c-tableCellAlternate);
          padding: size(m);
          border-radius: 50%;
          display: flex;
          align-items: center;

        }

        .label-container {
          display: flex;

        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .box-container {
    flex-direction: column;
  }
}

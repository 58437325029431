@import '../../../foundation/dls/theme/generated.colors';
@import '../../../foundation/dls/sizes/generated.sizes';
@import '../../../foundation/dls/sizes/generated.fonts';

div {
  .red-container {
    display: flex;
    //width: 119%;
    height: 48px;
    background-color: color($c-redErrorBackground);
    //align-content: flex-end;
    align-items: center;
    gap: 16.5px;
    margin-left: -20px;
    margin-right: -28px;
    padding-inline: 11px;
    justify-content: flex-start;
  }
  .white-warning-label {
    font-size: 20px;
    font-weight: bold;
  }

  .white-warning-img {
    height: 21px;
    width: 21px;
  }
}

@import './stylesheet/main';
@import './stylesheet/responsive';
@import './stylesheet/font-face';
@import 'foundation/dls/sizes/generated.sizes';

.App {
  text-align: center;
  height: 100%;
}

//.App-logo {
//  //height: size(xl);
//  pointer-events: none;
//}

.App-logo-small{
  height: 32px;
  pointer-events: none;
}

.App-header {
  background-color: #F5F6FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  background: #ffffff;
}

html, body {
  font-family: 'Sukhumvit Set', sans-serif;
}

html.thai,
body.thai {
}

#render-target, .root {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  display: inline-block;
  width: 80%;
  height: 100%
}

.notifications {
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem;
}

.detail-page {
  overflow: auto;
  box-sizing: border-box;
  transition: transform 0.5s, opacity 0.5s;
}

.detail-page.transition-appear {
  opacity: 0;
  /*transform: translate3d(100%, 0, 0);*/
}

.detail-page.transition-appear.transition-appear-active {
  opacity: 1;
  /*transform: translate3d(0, 0, 0);*/
}

.detail-page.transition-leave {
  opacity: 1;
  /*transform: translate3d(0, 0, 0);*/
}

.detail-page.transition-leave.transition-leave-active {
  opacity: 0;
  /*transform: translate3d(100%, 0, 0);*/
}

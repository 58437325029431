@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';

.toolbar,
.large-toolbar {
  background-color: #FFFFFF;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
  //border-bottom: $size-border-width solid color($c-neutral);
  width: 100%;
  padding-block: size(xxs);
  align-items: center;
  display: flex;
  flex: 0.1;
  position: sticky;
  top: 0;
  z-index: 10;
  //filter: drop-shadow(0px 4px #0000001A);


  .profile {
    //background-color: #9766E3;
    display: flex;
    flex: 1 0 auto;
    justify-self: flex-end;
    justify-content: flex-end;
    padding-inline: size(m);
    flex-direction: row;
    align-items: center;

    .info-section {
      flex-direction: column;

    }

    .logout-icon {
      cursor: pointer;
      width: auto;
      height: 42px;
    }
  }

  .app-logo-toolbar,
  .app-large-logo-toolbar,
  {
    padding-left: size(s);
    width: auto;
    height: 58px;
    cursor: pointer;
  }
}

.login-toolbar {
  drop-shadow: (0px 1px 2px rgba(0, 0, 0, 0.16));
  border-bottom: $size-border-width solid color($c-neutral);
  width: 100%;
  padding-block: size(xxxs);
  padding-inline: size(xs);
  align-items: center;
  justify-content: space-between;
  display: flex;
  //flex: 0.1;
  height: 64px;
  transition: all 0.5s ease;

  .app-logo {
    margin-left: size(xxxs);
    width: auto;
    height: 58px;
    cursor: pointer;
  }

  .search-container {
    flex: 0.4;
    padding-block: size(xxs);
    transition: all 0.5s ease;
  }
}

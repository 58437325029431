@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';

.admin-parent-container {
  width: 100%;
  height: 100%;
  flex: 0;
  display: flex;
  flex-direction: column;

  .admin-body {
    background-color: #F5F6FF;
    flex: 10 10 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 1;
    padding-block: size(s);
    padding-inline: size(m);

    .summary {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      flex: 10 10 auto;
      //background-color: color($c-cardBg);
      border-radius: size(xs);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
      justify-content: space-evenly;

      .icon-container {
        background-color: color($c-white);
        padding: size(l);
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        align-self: center;
        border-radius: 150px;
        width: 5rem;
        height: 5rem;
      }
    }

    .stepper-container {
      background-color: color($c-white);
      padding-inline: size(xs);
      padding-block: size(m);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
      border-radius: size(xs);

    }

    .status-container {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
    }

    .event-summary-card {
      background-color: color($c-cardBg);
      padding-inline: size(l);
      padding-block: size(m);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
      border-radius: size(xs);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      flex: 0 0 auto;
      //padding-bottom: size(l);

      .summary-card {
        //background-color: color($c-tableCellSelected);
        display: flex;
        justify-content: space-evenly;
        padding-inline: size(m);
        padding-block: size(xs);

        .heading {
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: row;
        }

        .divider {
          //width: 2.5px;
          height: 100%;
          background-color: grey;
          border-radius: 15px;
          flex: 0 0 2.5px;
        }

        .summary-row {
          //background-color: color($c-tableCellSelected);
          justify-content: center;
          align-items: center;
          display: flex;
          padding-block: size(xs);
          flex: 1 1;
        }
      }

      .title-container {
        background-color: color($c-tableCellSelected);
        display: flex;
        padding-inline: size(m);
        padding-block: size(s);
        align-items: center;
        justify-content: flex-start;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        border-radius: size(xs);
      }

      .button-container {
        display: flex;
        flex-direction: column;
        padding-top: size(xl);
      }
    }

    .card {
      background-color: color($c-cardBg);
      padding-inline: size(l);
      padding-block: size(m);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
      border-radius: size(xs);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      flex: 3 3 auto;

      .title-container {
        background-color: color($c-tableCellSelected);
        display: flex;
        padding-inline: size(m);
        padding-block: size(s);
        align-items: center;
        justify-content: flex-start;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        border-radius: size(xs);
      }

      .button-container {
        display: flex;
        flex-direction: column;
        padding-top: size(xl);
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex: 1 1 auto;
      padding-inline: size(xs);
    }
  }
}

.area-condition {
  //background-color: #1f6fde;
  padding-block: size(m);
  padding-inline: size(s);
}

.loading-card {
  background-color: white;
  display: flex;
  padding-block: size(l);
  padding-inline: size(xl);
  border-radius: size(xs);
}

.event-condition-card {
  display: flex;
  justify-content: space-evenly;
  padding-block: size(xs);

  .heading {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
  }

  .divider {
    //width: 2.5px;
    height: 100%;
    background-color: grey;
    border-radius: 15px;
    flex: 0 0 2.5px;
  }

  .summary-row {
    //background-color: color($c-tableCellSelected);
    justify-content: center;
    align-items: center;
    display: flex;
    padding-block: size(xs);
    flex: 1 1;
  }
}
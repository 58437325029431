@font-face {
  font-family: 'Sukhumvit Set';
  src: local('Sukhumvit Set');
  src: url('fonts/SukhumvitSet-Text.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: local('Sukhumvit Set Bold');
  src: url('fonts/SukhumvitSet-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: local('Sukhumvit Set thin');
  src: url('fonts/SukhumvitSet-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: local('Sukhumvit Set Med');
  src: url('fonts/SukhumvitSet-Medium.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sukhumvit Set';
  src: local('Sukhumvit Set light');
  src: url('fonts/SukhumvitSet-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

input, select, textarea, button{font-family:inherit;}

@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';
.react-confirm-alert-body-element {
  overflow: auto !important;
}

.notification-float1 {
  border-radius: 3px;
  pointer-events: all;
  padding-block: size(xs);
  padding-inline: size(s);
  align-items: center;
  justify-content: stretch;
  opacity: 100%;
  transition: all 0.5s ease;
  align-self: flex-end;
  height: 6%;
  width: 200px;
  position: absolute;
  top: 7%;
  right: 2.5%;
  display: flex;
}

.notification {
  display: flex;
  flex-direction: row;
  flex: 0;
  border-radius: 3px;
  padding-block: size(xs);
  padding-inline: size(s);
  align-items: center;
  justify-content: stretch;
  opacity: 100%;
  transition: all 0.5s ease;
  align-self: flex-end;
  height: 100%;
}

.notification-icon {
  flex: 0;
  width: auto;
  height: 1rem;
  transition: all 0.5s ease;
}

.notification-label-container {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 100%;
  transition: all 0.5s ease;
}

.notification-x-icon {
  flex: 0;
  width: auto;
  height: 0.4rem;
  cursor: pointer;
  transition: all 0.5s ease;
}

.success-container {
  background: color($c-success1);
  border: 1px solid #D7EAD9;
}

.notification-icon-hidden {
  flex: 0;
  width: auto;
  height: 0;
  transition: all 0.5s ease;
}

.notification-label-container-hidden {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  height: 0;
  transition: all 0.5s ease;
}

.notification-x-icon-hidden {
  flex: 0;
  width: auto;
  height: 0;
  cursor: default;
  transition: all 0.5s ease;
}

.container-hidden {
  background: color($c-white);
  border: none;
  opacity: 0;
  height: 0;
}

.float-container-hidden {
  background: color($c-white);
  border: none;
  opacity: 0;
  height: 0;
  position: absolute;
}

.failure-container {
  background: color($c-redErrorBackground);
  border: 1px solid #EAD7D7;
}

.error-transparent-background {
  background: transparent !important;
  pointer-events: none;
}

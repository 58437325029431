@import '../../../foundation/dls/theme/generated.colors';
@import '../../../foundation/dls/sizes/generated.sizes';
@import '../../../foundation/dls/sizes/generated.fonts';

::placeholder {
  color: color($c-greyDivider);
}
div {

  .text-field input:focus {
    outline: none !important;
    border: $size-border-width solid color($c-primary);
    transition: border 0.25s linear;
  }

  .text-field-error input:focus {
    outline: none !important;
    border: $size-border-width solid color($c-primary);
    transition: border 0.25s linear;
  }

  .username-password-textfield-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    width: 100%;
  }

  .username-password-textfield-container .label {
    font-size: 16px;
    white-space: nowrap;
    font-weight: bold;
  }

  .label-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: flex-start;
    padding-inline: 0;
  }

  .text-field-error input {
    //background-color: color($c-neutral);
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: font-size($f-subtitle);
  }

  .text-field-error {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 13px;
    font-weight: 300;
    min-width: 380px;
    border-radius: size(xs);
    padding: size(xxs) size(xs) size(xs) size(m);
    background-color: color($c-white);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
    border: 1.25px solid color($c-danger);
  }

  .text-field-password-error {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 13px;
    font-weight: 300;
    min-width: 380px;
    border-radius: size(xs);
    padding: size(xxs) size(xs) size(xs) size(m);
    background-color: color($c-white);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
    border: 1.25px solid color($c-danger);

    .input-password {
      flex: 1;
      font-size: font-size($f-subtitle);
      width: 100%;
      border: none;
      outline: none !important;
      transition: all 0.25s ease-in-out;
    }
    .input-password input:focus {
      border: none;
      outline: none !important;
      transition: all 0.25s ease-in-out;
    }
  }

  .text-field {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 13px;
    font-weight: 300;
    min-width: 380px;
    border-radius: size(xs);
    padding: size(xxs) size(xs) size(xs) size(m);
    background-color: color($c-white);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;
  }

  .text-field-password {
    height: 55px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 13px;
    font-weight: 300;
    min-width: 380px;
    border-radius: size(xs);
    padding: size(xxs) size(xs) size(xs) size(m);
    background-color: color($c-white);
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.08));
    box-shadow: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    transition: all 0.25s ease-in-out;

    .input-password {
      flex: 1;
      font-size: font-size($f-subtitle);
      width: 100%;
      border: none;
      outline: none !important;
      transition: all 0.25s ease-in-out;
    }

    .input-password input:focus {
      border: none;
      outline: none !important;
      transition: all 0.25s ease-in-out;

    }

  }

  .text-field-password:focus-within {
    border: $size-border-width solid color($c-primary);
    transition: all 0.25s ease-in-out;
  }

  .text-field:focus-within {
    border: $size-border-width solid color($c-primary);
    transition: all 0.25s ease-in-out;
  }

  .text-field-password input {
    //background-color: color($c-neutral);
    overflow: visible;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    transition: all 0.25s ease-in-out;

  }

  .text-field input {
    //background-color: color($c-neutral);
    font-size: font-size($f-subtitle);
    font-style: normal;
    font-weight: 300;
    width: 100%;
    height: 100%;
    overflow: visible;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .text-field-password input:focus {
    border: none !important;
  }

  .text-field-password-error input {
    font-size: font-size($f-subtitle);
    font-style: normal;
    font-weight: 300;
    width: 100%;
    height: 100%;
    overflow: visible;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .text-field-password-error input:focus {
    outline: none !important;
  }

  .text-field-password input:focus {
    outline: none !important;
  }

  .text-error-inline {
    display: flex;
    align-items: center;
    gap: 5px;
    align-self: flex-start;

    .warning-label {
      margin-top: 2px;
      font-size: 16px;
      font-weight: normal;
    }

    .red-warning-img {
      height: 13px;
      width: auto;
    }
  }
}

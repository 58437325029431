@import '../../../foundation/dls/theme/generated.colors';
@import '../../../foundation/dls/sizes/generated.fonts';

.truncated-text {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

div {
  .label-jumbotron {
    font-size: font-size(jumbotron);
  }

  .label-title-light {
    font-size: font-size(title);
  }

  @each $font-name in $font-names {
    @each $color-name, $color in $color-variants {
      .label-#{$font-name}[color=#{ $color-name }] {
        color: color($color-name);
        transition: all 0.5s ease;
      }

      .label-#{$font-name} {
        font-size: font-size($font-name);
        font-family: 'Sukhumvit Set';
        font-style: normal;
        font-weight: 300;
        transition: all 0.5s ease;
      }

      .label-#{$font-name}-bold[color=#{ $color-name }] {
        font-weight: bold;
        color: color($color-name);
        transition: color 0.5s linear;
      }

      .label-#{$font-name}-bold {
        font-size: font-size($font-name);
        font-weight: bold;
        transition: color 0.5s linear;
      }

      .label-#{$font-name}-bold-underline[color=#{ $color-name }] {
        font-weight: bold;
        color: color($color-name);
        transition: color 0.5s linear;
        text-decoration-thickness: 1.1px;
        text-underline-offset: 2.5px;
        text-decoration: underline;
        text-decoration-color: color($color-name);
      }

      .label-#{$font-name}-bold-underline {
        font-size: font-size($font-name);
        font-weight: bold;
        text-decoration-thickness: 0.8px;
        text-underline-offset: 2px;
        text-decoration: underline;
      }
    }
  }

  .label-title, .label-jumbotron {
    font-weight: bold;
  }
}

.label-two-line-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@import 'src/foundation/dls/sizes/generated.sizes';
@import 'src/foundation/dls/theme/generated.colors.scss';

.view-event-textfield {
  width: 100%;
  height: 100%;
  flex: 0;
  gap: 20px;
  display: flex;
  flex-direction: row;
}
.view-event-parent-container {
  width: 100%;
  height: 100%;
  flex: 0;
  display: flex;
  flex-direction: column;

  .view-event-body {
    background-color: #F5F6FF;
    flex: 10 10 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 1;
    padding: size(m);
    padding-inline: size(m);

    .card {
      background-color: color($c-cardBg);
      padding-inline: size(m);
      padding-block: size(m);
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
      border-radius: size(xs);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      flex: 1 0 auto;

      .title-container {
        background-color: color($c-tableCellSelected);
        display: flex;
        padding-inline: size(m);
        padding-block: 0.8rem;
        align-items: center;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        border-radius: size(xs);
      }

      .export-container {
        background-color: color($c-white);
        display: flex;
        padding-inline: size(m);
        padding-block: size(m);
        align-items: center;
        justify-content: space-between;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.05));
        border-radius: size(xs);
      }
    }

  }

  .loading-card {
    background-color: white;
    display: flex;
    padding-block: size(l);
    padding-inline: size(xl);
    border-radius: size(xs);
  }

}

@media only screen and (max-width: 600px) {
  .box-container {
    flex-direction: column;
  }
}
